/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Layout } from '../../components';
import NavigationBarV2 from "../NavigationBarV2";
import {Button, Wrapper} from "./styles";

class NotFound extends PureComponent {
  componentDidMount() {
    const {
      history
    } = this.props;
    window.scrollTo(0,0);
    history.push('/404');
  }
  render() {
    const { history } = this.props;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
        />
        <Wrapper>
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-404">
                <h1>Oops!</h1>
              </div>
              <h2>404 - Page not found</h2>
              <p>The page you are looking for might have been removed had its name changed or is temporarily
                unavailable.</p>
              <Button onClick={() => history.push('/')}>Click here for home</Button>
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

NotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(NotFound);