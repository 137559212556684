import styled, {css} from 'styled-components';

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
//const LightFont = 'Rubik-Light'

const NotificationWrapper =  styled.div`
  position : absolute;
  top: 75px;
  border-radius: 6px;
  z-index: 1999;
  box-shadow: 1px 1.732px 12px rgba(0, 0, 0, 0.18);
  background-color: #fff;
  width: 375px;
  right: 100px;
  .read{
    background-color: #F6F6F8;
  }
  .heading  {
    min-height: 60px;
    width: 100%;
    background-color: #F6F6F8;
    padding: 10px;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #D8D8D8;
    &:first-child {
    background-color: #fff;
    border-radius: 6px 6px 0 0;
    padding: 20px 15px;
    cursor: default;
    }
     div {
      display: flex;
      margin-left: auto;
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        > span {
          font-family: ${MediumFont};
          font-size: 16px;
          color: #3E3F42;
          display: block;
        }
        > span:last-child{
          cursor: pointer;
          font-family: ${({fontFamily}) => fontFamily ? `${BoldFont}`: `${MediumFont}`};
        }
      }
    }
}
  > ul {
    list-style: none;
    width: 100%;
    padding: 0;
    height: ${({height}) => height ? '500px': ``};
    overflow-y: ${({height}) => height ? 'auto': ``};
    overflow-y: auto;

.buttonWrapper{
display: flex;
width:100%;
padding-left:60px;
.button{
  height:30px;
  width:100px !important;
  background:transparent;
  border-radius:3px;
  cursor:pointer;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #85C0EA;
  display:flex;
  justify-content:center;
  align-items:center;
  .wish{
    height:30px;
    width:100px !important;
    border: 1px solid #85C0EA;
    background:transparent;
    border-radius:3px;
    cursor:pointer;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #85C0EA;
    display:flex;
    justify-content:center;
    align-items:center;
  }
}
.time{
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #F4AAA9;
  display:flex;
  margin:auto;
  margin-right:0px;
  justify-content: flex-end;
}}
    ::-webkit-scrollbar {
      width: 5px;
      height: 2px;
    }
  
    ::-webkit-scrollbar-thumb {
      background: #c6c2c2;
      cursor: pointer;
      border-radius: 2.5px;
    }
  
    ::-webkit-scrollbar-track {
      background: #f2f0f0;
      border-radius: 2.5px;
    }
    > li {
      min-height: 60px;
      width: 100%;
      background-color: #fff;
      padding: 15px 10px;
      display: block;
      cursor: pointer;
      border-bottom: 1px solid #D8D8D8;
      .showDot{
        width:10px;
        height: 40px;
        display:flex;
        margin:auto;
        .color{
          width:10px;
          height:10px;
          background: #F4AAA9;
          border-radius:50%;
          display: flex;
          margin: auto;
        }
        .no-color{
          width:10px;
          height:10px;
          background: transparent;
          border-radius:50%;
          display: flex;
          margin: auto;
        }
      }
      // &:first-child {
      // background-color: #fff;
      // border-radius: 6px 6px 0 0;
      // padding: 20px 15px;
      // cursor: default;
      // }
      &:last-child {
       border: none;
      }
       div {
        display: flex;
        margin-left: auto;
        >div{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          > span {
            font-family: ${MediumFont};
            font-size: 16px;
            color: #005C87;
            display: block;
          }
          > span:last-child{
            cursor: pointer;
            font-family: ${({fontFamily}) => fontFamily ? `${BoldFont}`: `${MediumFont}`};
          }
        }
      }
      
      > span {
        font-family: ${MediumFont};
        font-size: 16px;
        color: #005C87;
        display: block
      }
      > h5 {
        font-family: ${MediumFont};
        font-size: 14px;
        color: #F4AAA9;
        display: block;
        // margin-left: 45px;
        margin: 0px;
        width: 100%;
        display: flex;
        padding-left: 55px;
      }
      
      > p {
        float: left;
        margin: 0px 10px 5px;
        color: #005C87;
        font-family: ${MediumFont};
        font-size: 14px;
        width: calc(100% - 80px);
        padding-right:5px;
        > a {
          color: #005C87;
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
				}
        &:(nth-child(2)){
          display: block;
        }
			}
			> p: nth-child(2) {
				color: #005C87;
			}
      
      > a {
        margin-left: auto;
      }
    }
    
    &::before {
      // width: 20px;
      // height: 20px;
      background: #fff;
      position: absolute;
      content: '';
      top: -10px;
      right: 23%;
      transform: rotate(-45deg);
      @media(max-width: 600px) {
        display: none;
      }
    }
    
  }
  @media (max-width: 500px) {
    left: 50%;
    transform: translate(-50%);
  }
  @media (max-width: 600px){
    width: 90%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%);
  }
`;

const ImageWrapper =  styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    background: ${(props) => props.backgroundColor ? props.backgroundColor: 'white'};
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    > img {
      height: ${(props) => props.width ? props.width: '30px'};
      width: auto;
    }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${BoldFont};
  padding: 0px 25px 10px 10px;
  cursor: pointer;
  position: relative;
  
  > svg {
    margin-top: 3px;
    fill: #67676A;
    width: 20px;
    height: 20px;
    
    &:hover {
      fill: #71A147;
    }
  }

  ${({ active }) => active && css`
    > svg {
      fill: #71A147;
    }
  `}
`;
const Button = styled.button`
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  background: #fff;
  border-radius: 0 0 6px 6px;
  text-transform: none;
  cursor: pointer;
  border: none;
  color: #BCBCDE;
  outline: none;
  &:hover, &:active {
    border: none;
    outline: none;
  }
  
`;
const Text = styled.span`
font-family: ${BoldFont};
text-transform: uppercase;
`;

const TopHeading = styled.div`
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
display: flex;
padding: 15px 0px 15px 25px;
background: #F7F5EB;
display: flex;
color: #005C87;
>img{
  height:24px;
  width:24px;
  display:flex;
  margin:auto;
  margin-right:15px;
}
`;

const Container = styled.div`
display: flex;
background: white;
height: 60px;
.buttonWrapper{
  display: flex;
  margin:auto;
  margin-left:15px;
}
.readText{
font-family: Rubik-Medium;
font-size: 12px;
font-weight: 400;
line-height: 10px;
letter-spacing: 0px;
text-align: left;
color: #005C87;
display: flex;
margin: auto;
margin-right:25px;
cursor:pointer;
text-decoration: underline;
}
`;

const ReadUnreadButton = styled.div`
display: flex;
min-width: 80px;
height: 30px;
border: ${({active}) => active ? 'none': '1px solid #005C8799'};
background: ${({active}) => active ? '#005C87': 'white'};
text-align:center;
align-items:center;
justify-content:center;
border-radius:3px;
margin-left:${({margin}) => margin ? '10px': '0px'};
font-family: ${({active}) => active ? 'Rubik-Medium': 'Rubik'};
font-size: 16px;
line-height: 20px;
color: ${({active}) => active ? 'white': '#005C8799'};
cursor:pointer;
padding: 0px 10px;
`;

const NotificationType = styled.div`
display: flex;
font-family: Rubik;
font-size: 14px;
line-height: 20px;
color: #0D4270;
padding: 4px 0px 5px 15px;
`;

export {NotificationWrapper, ImageWrapper, IconContainer, Button, Text, TopHeading, Container, ReadUnreadButton, NotificationType};