/* eslint-disable no-undef*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CustomButton, CustomOrderedList } from '../MedicalForm/styles';

import QuestionComponent from '../QuestionComponentV2';

import { saveNutritionData, getNutritionData } from "../../redux/actions";
import { withTranslation } from 'react-i18next';

class NutritionForm extends Component {

  componentDidMount() {
    this.props.fetchNutritionData();
  }

  navigateUser = (e, value) => {
    e.preventDefault();
    const { history } = this.props;
    history.push(value);
  };

  render() {
    const { nutritionData, addNutritionData, hraQuestionsAnswersData } = this.props;
    return (
      <form>
        <CustomOrderedList>
          {
            hraQuestionsAnswersData['nutrition'].map((section, index) => {
              let addedAnswers = '';
              nutritionData && nutritionData.length > 0 && nutritionData.map((data) => {
                if(data.question === section.question_id) {
                  addedAnswers = data.answer
                }
              });
              return (
                <QuestionComponent
                  key={section.question}
                  section={section}
                  saveData={addNutritionData}
                  addedAnswers={addedAnswers}
                  color={"#8BBD53"}
                  questionNo={index+1}
                />
              )
            })
          }
        </CustomOrderedList>
        <CustomButton
          left
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/fitness')}
        >
          {this.props.t("back")}
        </CustomButton>
        <CustomButton
          onClick={(e) => this.navigateUser(e, '/wellness-assessment/stress')}
          disabled={_.isUndefined(nutritionData) || nutritionData.length<hraQuestionsAnswersData['nutrition'].length}
        >
          {this.props.t("next")}
        </CustomButton>
      </form>
    )
  }
}

NutritionForm.propTypes = {
  addNutritionData: PropTypes.func.isRequired,
  fetchNutritionData: PropTypes.func.isRequired,
  nutritionData: PropTypes.array,
  history: PropTypes.object.isRequired,
  hraQuestionsAnswersData: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  nutritionData: state.wellBeingAssessment.nutritionData
});

const mapDispatchToProps = (dispatch) => ({
  addNutritionData: (question, answer, isMultipleAnswers, count) => dispatch(saveNutritionData(question, answer, isMultipleAnswers, count)),
  fetchNutritionData: () => dispatch(getNutritionData())
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(NutritionForm));