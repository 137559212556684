import styled from "styled-components";

export const Button = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #1e76ab;
  display: block;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-weight: 700;
  padding: 20px 30px;
  margin: 30px auto 0;
  -webkit-box-shadow: 0px 4px 15px -5px #1e76ab;
          box-shadow: 0px 4px 15px -5px #1e76ab;
  &:focus, &:active {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  #notfound,
  #notfound .notfound {
    position: relative;
  }
  
  .notfound {
    width: 100%;
    text-align: center;
    .notfound-404 {
      position: relative;
      z-index: -1;
    }
    .notfound-404 h1 {
      font-family: 'Montserrat', sans-serif;
      font-size: 230px;
      margin: 0px;
      font-weight: 900;
      color: #E6F6FF;
      -webkit-background-clip: text;
    }
    h2 {
      font-family: 'Montserrat', sans-serif;
      color: #000;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 30px;
    }
    p {
      font-family: 'Montserrat', sans-serif;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      margin: 0 auto;
      max-width: 410px;
    }
  }  
  
  @media only screen and (max-width: 767px) {
      .notfound { 
        .notfound-404 {
          height: 142px;
        }
        .notfound-404 h1 {
          font-size: 112px;
        }
      }
  } 
`;